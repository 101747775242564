var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("slide-heading", { attrs: { heading: _vm.slide.heading } }),
      _vm.loaded
        ? [
            _vm.showError
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                    ),
                  ]),
                ])
              : [
                  _c("slide-image-and-description", {
                    attrs: { slide: _vm.slide },
                  }),
                  _c(
                    "div",
                    { attrs: { id: "document-download-slide-component" } },
                    [
                      _vm.loaded && !_vm.showError
                        ? _c(
                            "div",
                            [
                              _vm.resource
                                ? _c("stageline-pdf-viewer", {
                                    ref: "pdf-viewer",
                                    staticClass: "stageline-pdf-viewer",
                                    attrs: {
                                      "read-only": true,
                                      "show-pagination": true,
                                    },
                                    on: {
                                      "update:current-page":
                                        _vm.currentPageUpdated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 buttons mt-3 mb-0 d-flex flex-column",
                        },
                        [
                          ["xs", "sm"].includes(_vm.$mq)
                            ? _c(
                                "div",
                                { staticClass: "full-screen-buttons" },
                                [
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "file-button",
                                        attrs: { variant: "default" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.printPdf()
                                          },
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            type: "printer",
                                            "stroke-width": 2,
                                            height: "24",
                                            width: "24",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                Print\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "file-button",
                                        attrs: {
                                          variant: "default",
                                          "aria-label": "download button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadPdf()
                                          },
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            type: "download-cloud",
                                            "stroke-width": 2,
                                            height: "24",
                                            width: "24",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                Download\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.editEnabled
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "file-button",
                                            attrs: {
                                              variant: "default",
                                              "aria-label": "edit button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editPdf()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Edit\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "slide-viewer-button-row mt-3 mb-0",
                            },
                            [
                              !_vm.editEnabled
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "default",
                                        "aria-label": "back button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("previous-slide")
                                        },
                                      },
                                    },
                                    [
                                      _c("fa-icon", {
                                        staticClass: "fa-xs",
                                        attrs: { icon: "chevron-left" },
                                      }),
                                      _vm._v(
                                        "\n              Back\n            "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !["xs", "sm"].includes(_vm.$mq)
                                ? [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "file-button",
                                        attrs: {
                                          variant: "default",
                                          "aria-label": "print button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.printPdf()
                                          },
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            type: "printer",
                                            "stroke-width": 2,
                                            height: "24",
                                            width: "24",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                Print\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "file-button",
                                        attrs: {
                                          variant: "default",
                                          "aria-label": "download button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadPdf()
                                          },
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            type: "download-cloud",
                                            "stroke-width": 2,
                                            height: "24",
                                            width: "24",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                Download\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.editEnabled
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "file-button",
                                            attrs: {
                                              variant: "default",
                                              "aria-label": "edit button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editPdf()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Edit\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _c(
                                "b-button",
                                {
                                  staticClass: "next-button",
                                  attrs: {
                                    variant: "primary",
                                    "aria-label":
                                      _vm.nextButtonLabel + " button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.next()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.nextButtonLabel) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
          ]
        : _c("ct-centered-spinner", [
            _vm._v("\n    " + _vm._s(_vm.loadingText) + "\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }