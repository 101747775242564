import axiosClient from '@/http'

export const downloadPrintMixin = {
  methods: {
    async download(resource, formData, company) {
      const url = await this.getUrl(resource, formData)
      const a = document.createElement('a')
      a.style = 'display: none'
      a.href = url
      a.target = '_blank'
      a.download = resource?.name && company?.name ?
        `${resource.name} ${company.name}.pdf` :
        `filing.pdf`

      document.body.appendChild(a)

      a.click()

      setTimeout(() => {
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 1000)
    },
    async print(resource, formData) {
      const url = await this.getUrl(resource, formData)
      const printWindow = window.open(url, '_blank')

      setTimeout(printWindow.window.print(), 1000)
    },
    async getUrl(resource, formData) {
      const response = await axiosClient.post(`resources/${ resource.id }/fill_fields`,
        { form_data: formData },
        { responseType: 'blob' },
      )

      const url = URL.createObjectURL(response.data)
      return url
    },
  },
}
